import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { KitAssemblyPreparation } from "../../components/Formik/AllForms/kitAssemblyPreparation";
import { setAssemblyForStagingBuild } from "../../Store/reducers/orders";

import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { HemaValue, HemaLabel } from "../../utils";
import { Alert as AlertBootstrap } from "react-bootstrap";
import eyeIcon from "../../assets/images/binocular.svg";
import { Button } from "../../utils";
import { setBuildTemplate, setForm } from "../../Store/reducers/uiSettings";
import Heading from "../../pages/logistOrchestra/logisticComponents/heading";
import { setFormCloseReducer, showSuccessReducer, showErrorReducer, setFormLoaderReducer } from "../../Store/reducers/uiSettings";
import { setActiveDynamicDoc } from "../../Store/reducers/orders";
import { CreateItemPurple, Assign } from "../../HemeIconLibrary";
import { ScreeningKitBuild } from "../../components/Formik/AllForms/ScreeningKitBuilder";
import {
  AssignPreparationKitAction,
  getAllbuildPreparationAction,
  AssignPreLineItemAction,
  getAssemblyforItemAction,
  getReadyInvenotryItemAction,
  getShipmentDetailAction,
  getdynamicdocsAction,
} from "../../Actions/order";
const KitpreparationTable = (props) => {
  const { setformName, setFormIcon, setokBtnText, setCancelBtnText, setCTA, confirmSlide, setokBtnIcon, setUpdatedData, setconfirmSlide } = props;
  const [filteredData, setfilteredData] = useState();
  const [assemblyAPI, setAssemblyAPI] = useState();
  const { uisettings, sponsers, common, orders } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      if (uisettings?.formName === "shipping-assembly-preparation") {
        // const res = await getShipmentDetailAction(
        //   orders.activeOrder?.id,
        //   uisettings?.editForm?.id
        // );
        // setfilteredData(
        //   orders?.builtAssembly?.map((ass) => {
        //     if (ass.id === 1) {
        //       return {
        //         ...ass,
        //         status: {
        //           ...ass.status,
        //           name: res?.data?.isAwbBuilt ? 'Ready' : 'Not Ready',
        //         },
        //       };
        //     } else if (ass.id === 2) {
        //       return {
        //         ...ass,
        //         status: {
        //           ...ass.status,
        //           name: res?.data?.isProFormaBuilt ? 'Ready' : 'Not Ready',
        //         },
        //       };
        //     } else if (ass.id === 3) {
        //       return {
        //         ...ass,
        //         status: {
        //           ...ass.status,
        //           name: res?.data?.isPackingSlipBuilt ? 'Ready' : 'Not Ready',
        //         },
        //       };
        //     }
        //     {
        //       return ass;
        //     }
        //   })
        // );
        const res = await getdynamicdocsAction(orders.activeOrder?.id, uisettings?.editForm?.id);
        if (res.status === 200) {
          setfilteredData(res.data?.items);
          setAssemblyAPI(res.data?.assembly);
        }
      } else {
        setfilteredData(orders?.builtAssembly);
      }
    })();
  }, [orders?.builtAssembly, uisettings?.buildTemplate]);

  const buildPrepKitAssembly = async (row) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));

      const filterpayload = {
        // quantity: parseInt(uisettings?.editForm?.builtQuantity) * parseInt(row.quantity),
        quantity: uisettings?.editForm?.builtQuantity,
        orderItemId: row?.orderItemId,
        itemRecordId: payload?.recordItemId?.filter?.((item) => item.checked)?.[0]?.id || null,
      };
      if (filterpayload?.itemRecordId) {
        const resp = await AssignPreparationKitAction(orders?.activeOrder?.id, filterpayload, orders?.activeBuildStaging?.id);
        if (resp?.status === 200) {
          // dispatch(setFormCloseReducer());
          dispatch(showSuccessReducer(`kit item successfully built.`));
          getAllbuildPreparationAction("staging", orders.activeOrder?.id);
          const resp = await getAssemblyforItemAction(orders?.activeOrder?.id, uisettings?.editForm?.id);
          if (resp.status === 200) {
            setfilteredData(resp.data?.orderItemAssemblies);
            const selectedAssemblies = resp.data;
            dispatch(setAssemblyForStagingBuild(selectedAssemblies?.orderItemAssemblies));
            setformName("Kit Assembly Preparation");
            setokBtnText("Save");
            setokBtnIcon("");
            setUpdatedData(KitAssemblyPreparation);
            dispatch(
              setForm({
                state: true,
                type: "kit-assembly-preparation",
              }),
            );
            setCTA(() => async () => {
              dispatch(
                setForm({
                  state: false,
                  type: '',
                }),
              );
            });
          }
        }
        dispatch(setFormLoaderReducer(false));
      } else {
        dispatch(showErrorReducer(`There is no inventory selected, please select inventory from the ready tab.`));
        dispatch(setFormLoaderReducer(false));
      }
    });
  };

  const buildPrepLineItemCTA = async (row) => {
    // setCTA(() => async (payload) => {
    dispatch(setFormLoaderReducer(true));

    const resp = await AssignPreLineItemAction(orders.activeOrder?.id, row.id, orders?.activeBuildStaging?.id);
    if (resp?.status === 200) {
      dispatch(setFormLoaderReducer(false));
      const resp = await getAssemblyforItemAction(orders?.activeOrder?.id, uisettings?.editForm?.id);
      if (resp.status === 200) {
        setfilteredData(resp.data?.orderItemAssemblies);
      }
      getAllbuildPreparationAction("staging", orders.activeOrder?.id);
      // dispatch(setFormCloseReducer());
      dispatch(showSuccessReducer(`Line Item successfully built.`));
      setformName("Kit Assembly Preparation");
      setconfirmSlide("");
      setokBtnText("Save");
      setCancelBtnText("Cancel");
      setCTA(() => async () => {
        dispatch(
          setForm({
            state: false,
            type: "",
          }),
        );
      });
    }
    // });
  };
  return (
    <>
      {uisettings?.formName === "shipping-assembly-preparation" && (
        <div className="flex gap-[40px] mb-[30px]">
          <HemaLabel text={"Assembly Name"} className="font-normal text-[#000000]" />

          <HemaLabel text={assemblyAPI?.name || "NA"} className="font-normal text-[#000000]" />
        </div>
      )}
      {confirmSlide?.buildType ? (
        <>
          <Heading heading={`Item Details`} buttonData={undefined} border={undefined} mb={undefined} />
          <div className="flex mb-[30px] mt-4">
            <HemaLabel className="w-[265px]" text={"Item Name"} />
            <HemaValue text={confirmSlide?.item?.name} />
          </div>
          <div className="flex mb-[30px]">
            <HemaLabel className="w-[265px]" text={"Number of items left to assign:"} />
            <HemaValue text={confirmSlide?.remainingQuantity} />
          </div>
          <Heading heading={`Confirmation Message`} buttonData={undefined} border={undefined} mb={undefined} />
          <div className="flex w-full my-4">
            <HemaLabel isLower={true} className="w-full" text={"Please confirm that you have prepared this line item to be included in this request."} />
          </div>
        </>
      ) : filteredData ? (
        filteredData?.length > 0 ? (
          uisettings?.formName === "kit-assembly-preparation" ? (
            filteredData?.map((ass, counter) => {
              return (
                <>
                  <Heading heading={`Assembly Details`} buttonData={undefined} border={undefined} mb={undefined} />
                  <div className="flex mb-4">
                    <HemaLabel className="w-[265px]" text={"Assembly Name"} />
                    <HemaValue text={ass?.name} />
                  </div>
                  <div className="flex mb-4">
                    <HemaLabel className="w-[265px]" text={"Testing Lab"} />
                    <HemaValue text={ass?.testingLab?.name} />
                  </div>
                  <div className="flex mb-4">
                    <HemaLabel className="w-[265px]" text={"Inbound Condition"} />
                    <HemaValue text={ass?.inboundShippingCondition?.name} />
                  </div>
                  <div className="border border-[#ccc] rounded-[5px] mb-4">
                    <DataTable
                      columns={[
                        {
                          name: <HemaValue text={"Item Name"} className="font-normal text-[#000000]" />,
                          grow: 10,
                          selector: (row, index) => {
                            return (
                              <div className="flex-shrink-0 flex items-center gap-[10px] cursor-pointer check_box">
                                <HemaValue text={uisettings?.formName === "shipping-assembly-preparation" ? row?.name : row?.item?.name} className="whitespace-break-spaces" />
                              </div>
                            );
                          },
                        },
                        {
                          name: <HemaValue text={"Quantity"} className="font-normal text-[#000000]" />,
                          selector: (row) => <HemaValue text={row?.quantity} />,
                        },
                        {
                          name: <HemaValue text={"Lot Number"} className="font-normal text-[#000000]" />,
                          grow: 12,
                          selector: (row) => <HemaValue className="whitespace-break-spaces" text={row?.lotNumber ? row?.lotNumber : "N/A"} />,
                        },
                        {
                          name: <HemaValue text={"Status"} className="font-normal text-[#000000]" />,
                          selector: (row) => (
                            <HemaValue text={uisettings?.formName === "shipping-assembly-preparation" ? (row?.file || row.owner ? "Ready" : "Not Ready") : row?.status?.name} />
                          ),
                        },
                        {
                          name: "Actions",
                          cell: (row) => {
                            return (
                              <div className=" four">
                                <div className="flex justify-end gap-[5px] meta">
                                  <Button
                                    Icon={<img src={eyeIcon} alt="logo" />}
                                    color="text-white"
                                    bg="bg-bgActionDots"
                                    type="button"
                                    cta={async () => {
                                     // return;
                                      if (row?.buildType === "build-assign") {
                                        const resp = await getReadyInvenotryItemAction(row?.item?.id, parseInt(row?.quantity) || 1);
                                        if (resp.status === 200) {
                                          const selectedInventory = resp?.data;
                                          const itemName = row?.item?.name;
                                          setformName("Inventory Management");
                                          setFormIcon(<CreateItemPurple />);
                                          setokBtnText("Assign");
                                          setokBtnIcon(<Assign color="#fff" />);
                                          setCancelBtnText("Cancel");
                                          ScreeningKitBuild[1].label = "Item Name:";
                                          ScreeningKitBuild[1].initialValue = itemName;
                                          ScreeningKitBuild[2].label = "Number of items left to assign:";
                                          ScreeningKitBuild[2].initialValue = row?.quantity || "1";
                                          ScreeningKitBuild[4].initialValue = selectedInventory;

                                          setUpdatedData(ScreeningKitBuild.slice(0, 5));
                                          dispatch(
                                            setForm({
                                              state: true,
                                              type: "build-assembly-kit",
                                            }),
                                          );
                                        }
                                        buildPrepKitAssembly(row);
                                      } else if (row?.buildType === "assign-custom-line-item") {
                                        setformName("Line Item Preparation");
                                        setFormIcon(<CreateItemPurple />);
                                        setokBtnText("Confirm");
                                        setCancelBtnText("Back");
                                        setconfirmSlide(row);
                                        setCTA(() => () => {
                                          buildPrepLineItemCTA(row);
                                        });
                                      } else if (row?.buildType === "pro-forma") {
                                        dispatch(showErrorReducer("This document is not configured on this level yet"));
                                      }
                                      //todo remove this code with the new document upload form changes
                                      //   else if (row?.buildType === "rawb") {
                                      //   dispatch(setActiveDynamicDoc(row));
                                      //   setformName("RAWB");
                                      //   setFormIcon(<CreateItemPurple />);
                                      //   setokBtnText("Confirm");
                                      //   dispatch(
                                      //    setForm({
                                      //      state: true,
                                      //      type: "ask-option-document-upload",
                                      //    }),
                                      //  );
                                                                             
                                      // }
                                       else {
                                        dispatch(
                                          setBuildTemplate({
                                            open: true,
                                            orderItemDetail: row,
                                          }),
                                        );

                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          },
                          ignoreRowClick: true,
                          allowOverflow: true,
                          button: true,
                        },
                      ]}
                      data={ass?.orderItemAssemblyItems || []}
                      // onSort={(row, direction, sorted) => {
                      //   setfilteredData(sortedData(row.sortId, direction, sorted));
                      // }}
                    />
                  </div>
                </>
              );
            })
          ) : (
            <div className="border border-[#ccc] rounded-[5px] mb-4">
              <DataTable
                columns={[
                  {
                    name: <HemaValue text={"Item Name"} className="font-normal text-[#000000]" />,
                    grow: 10,
                    selector: (row, index) => {
                      return (
                        <div className="flex-shrink-0 flex items-center gap-[10px] cursor-pointer check_box">
                          <HemaValue text={uisettings?.formName === "shipping-assembly-preparation" ? row?.name : row?.item?.name} />
                        </div>
                      );
                    },
                  },
                  {
                    name: <HemaValue text={"Quantity"} className="font-normal text-[#000000]" />,

                    selector: (row) => <HemaValue text={row?.quantity} />,
                  },
                  {
                    name: <HemaValue text={"Status"} className="font-normal text-[#000000]" />,

                    selector: (row) => (
                      <HemaValue text={uisettings?.formName === "shipping-assembly-preparation" ? (row?.file || row.owner ? "Ready" : "Not Ready") : row?.status?.name} />
                    ),
                  },
                  {
                    name: "Actions",
                    cell: (row) => {
                      return (
                        <div className=" four">
                          <div className="flex justify-end gap-[5px] meta">
                            <Button
                              Icon={<img src={eyeIcon} alt="logo" />}
                              color="text-white"
                              bg="bg-bgActionDots"
                              type="button"
                              cta={async () => {
                                if (row?.buildType === "awb" || row?.buildType === "depot-awb") {
                                  dispatch(setActiveDynamicDoc(row));
                                  if (row?.buildType === "depot-awb") {
                                    setformName("Generate Depot Airway Bill");
                                  } else {
                                    setformName("Shipment Preparation");
                                  }

                                  setFormIcon(<CreateItemPurple />);
                                  setokBtnText("Confirm");

                                  dispatch(
                                    setForm({
                                      state: true,
                                      type: "ask-option-shipping-upload",
                                    }),
                                  );
                                } else {
                                  dispatch(
                                    setBuildTemplate({
                                      open: true,
                                      orderItemDetail: {
                                        ...uisettings?.editForm,
                                        buildType: row?.buildType,
                                      },
                                    }),
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      );
                    },
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
                ]}
                data={filteredData || []}
                // onSort={(row, direction, sorted) => {
                //   setfilteredData(sortedData(row.sortId, direction, sorted));
                // }}
              />
            </div>
          )
        ) : (
          <AlertBootstrap variant="danger" className="text-left mt-[20px]">
            There is no assembly attached to this kit
          </AlertBootstrap>
        )
      ) : (
        <>
          <br />
          <Skeleton count={3} />
          <br />
          <Skeleton count={3} />
          <br />
        </>
      )}
    </>
  );
};

export default KitpreparationTable;
