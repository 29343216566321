import React, { useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { HemaLabel, Button, Alert, FormCheckbox, HemaValue, FormText, ImageUpload } from '../../../utils';
//import { generateManualUploadAction, getAllDocumentsForOrderAction, getAllShippingStatsAction, getShipmentDetailAction } from '../../../Actions/order';
import { Cancel, NextIcon, CrossIcon, TrackingNumber, Upload, SelectUpload, UploadIcon, Confirm } from '../../../HemeIconLibrary';

import { setForm, showSuccessReducer, setBuildTemplate, setFormLoaderReducer, setUploadProgress } from '../../../Store/reducers/uiSettings';
import Heading from '../../../pages/logistOrchestra/logisticComponents/heading';
import { compareArrays } from '../../../helpers/commonHelper';
import {
  getdocumentDetailAction,
  generateAlltypeDocumentAction,
  getshipmentDocumentDetailsAction,
  getpackingDocumentDetailAction,
  getAllbuildPreparationAction,
  generateShippmentDcoumentAction,
  getShipmentDetailAction,
  getAllShippingStatsAction,
  getAssemblyforItemAction,
  getcourierServiceType,
  getAllDocumentsForOrderAction,
  createNewDoumentAction,
} from '../../../Actions/order'

//todo: delete this file it is not used 

const DocumentUpload = () => {
  const dispatch = useDispatch();
  const [optionSelected, setOptionSelected] = useState();
  const { orders, uisettings } = useSelector((state) => state);
  const formikRef = useRef();

  useEffect(() => {
    (async () => {
      console.log(await formikRef.current?.validateForm());
    })();
  }, [JSON.stringify(formikRef?.current), uisettings?.editForm]);
  return (
    <>
      {optionSelected ? (
        <Formik
          initialValues={{
            TrackingNumber: '',
            Packages: orders?.activeShipment?.packages,
            notebookdocs: '',
          }}
          innerRef={formikRef}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            
            
            if (!values.notebookdocs) {
              errors.notebookdocs = 'Required';
            }

            return errors;
          }}
          onSubmit={async (payload, { setSubmitting }) => {
            console.log('orders.activeOrder', orders.activeOrder)
            
            
            setSubmitting(true);
            const formData = new FormData()
            formData.append('file', payload.notebookdocs)
            dispatch(setFormLoaderReducer(true));
            const result = await createNewDoumentAction(orders.activeOrder?.id, 4, formData);
            dispatch(setFormLoaderReducer(false));
            if (result.status === 200) {
              getAllDocumentsForOrderAction(orders.activeOrder?.id);
              dispatch(showSuccessReducer('New document added'));
              dispatch(setUploadProgress());
              dispatch(
                setForm({
                  state: false,
                  type: '',
                }),
              );
            }


   
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            initialValues,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]">
              <div>
                <div className="w-full mb-[30px]">
                  <Heading heading={'Upload Kit Document Manually'} />
                </div>

               

                <div className="w-full items-start gap-[16px] mb-[25px]">
                  <HemaLabel text={'Upload File'} Icon={<UploadIcon purple />} className={`mb-[10px]`} color="#454545" />

                  <ImageUpload name={'notebookdocs'} onChange={handleChange} onBlur={handleBlur} value={values.notebookdocs} setFieldValue={setFieldValue} />
                  {errors.notebookdocs && touched.notebookdocs && <div className="error text-[red] text-[12px] pt-[2px]">{errors.notebookdocs}</div>}
                </div>
                
              </div>

              <div className="flex gap-[8px] justify-end my-[20px]">
                <Button
                  cta={() => {
                    dispatch(
                      setForm({
                        state: true,
                        type: 'kit-assembly-preparation',
                      }),
                    );
                  }}
                  type="button"
                  text="Cancel"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  Icon={<Cancel />}
                />

                {isSubmitting ? (
                  <Button
                    type="submit"
                    bg="bg-primary1"
                    text={
                      <>
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                      </>
                    }
                  />
                ) : (
                  <Button
                    type="submit"
                    text="Confirm"
                    bg="bg-primary1"
                    color="text-white"
                    Icon={<Confirm />}
                    disabled={compareArrays(initialValues, values) ? true : Object.keys(errors)?.length ? true : false}
                  />
                )}
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{
            options: '',
          }}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            if (!values.options) {
              errors.options = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values) => {
            if (values.options === '1') {
             
              console.log('doc upload',orders, uisettings )
              dispatch(
                setBuildTemplate({
                  open: true,
                  orderItemDetail:orders.activeDynamicDoc
                }),
              );
            } else {
              setOptionSelected(values);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]">
              <div>
                <div className={'w-full'}>
                  <Heading heading={'Return Airway Bill Details'} />
                  <div className="mt-[30px] mb-[25px]">
                    <HemaLabel
                      text={'Please select whether you would like to auto-generate the return airway bill for this shipment or if you want to manually upload it.'}
                      className={`mb-[10px]`}
                    />
                  </div>

                  <label className="flex items-center gap-[10px] mb-[20px]">
                    <FormCheckbox type="radio" name="options" onChange={handleChange} onBlur={handleBlur} value={1} />
                    Auto-Generate
                  </label>
                  <label className="flex items-center gap-[10px]">
                    <FormCheckbox type="radio" name="options" onChange={handleChange} onBlur={handleBlur} value={2} />
                    Manual Upload
                  </label>
                </div>

                <div className={'w-full'}>
                  <div className="mb-[30px]"></div>
                  {errors.options && touched.options && <div className="error text-[red] text-[12px] pt-[2px]">{errors.options}</div>}
                </div>
              </div>

              <div className="flex gap-[8px] justify-end my-[20px]">
                <Button
                  cta={() => {
                    dispatch(
                      setForm({
                        state: true,
                        type: 'kit-assembly-preparation',
                      }),
                    );
                  }}
                  type="button"
                  text="Cancel"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  Icon={<Cancel />}
                />

                {isSubmitting ? (
                  <Button
                    type="submit"
                    bg="bg-primary1"
                    text={
                      <>
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                      </>
                    }
                  />
                ) : (
                  <Button type="submit" text="Next" bg="bg-primary1" color="text-white" Icon={<NextIcon />} />
                )}
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default DocumentUpload;
