import React, { useEffect, useState, useRef } from 'react'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import {
  HemaLabel,
  FormText,
  FormSelect,
  FormSearchableSelect,
  FormTextarea,
  ImageUpload,
  FormSwitch,
  Button,
  HemaValue,
  LocationExplorer,
  FormCheckbox,
  SingleSelect,
  ScreeningKitTable,
  KitpreparationTable,
  FormMultiSelect,
  FormTags,
  ScreeningShippingTable,
  ViewTable,
  ViewKitItemTable,
  Nodata,
} from '../../utils'
import { ClsoeBtnForm } from '../../helpers/closebtnFormName'
import { compareArrays, nameArray } from '../../helpers/commonHelper'
import DataTable from 'react-data-table-component'
import {
  Confirm,
  Cancel,
  CrossIcon,
  SelectDownArrow,
  ImportIcon,
  Pdf,
  Add,
} from '../../HemeIconLibrary'

import { useDispatch } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { setForm, showErrorReducer } from '../../Store/reducers/uiSettings'
import { useSelector } from 'react-redux'
import { formats } from '../../utils/DummyData/formats'
import KitBuilder, { NewRow, DataTableRowItems } from './CustomForms/kit'
import EditKitBuilder from './CustomForms/editKit'
import StorageLocationForm from './CustomForms/StorageExporer'
import AssignShippingRules from './CustomForms/assignShippingRules'
import AssignShippingtoLower from './CustomForms/shippingToLeveltree'
import AddTOKit from './CustomForms/addKitToLogistic'
import UnconfiSite from './CustomForms/showUnconfigSite'
import AddNewRegion from './CustomForms/addregionToLogistic'
import AddNewCountry from './CustomForms/addCountryToLogistic'
import AddNewSite from './CustomForms/addSiteToLogistic'
import CheckboxTable from '../../utils/tableCheckbox'
import Acknowledgment from './CustomForms/acknowledge'
import CreatePackge from './CustomForms/createPackage'
import UploadShipping from './CustomForms/shipping-upload'
import DocumentUpload from './CustomForms/document-upload'
import CreateEmail from './CustomForms/createEmail'
import MArkQc from './CustomForms/markQC'
import Heading from '../../pages/logistOrchestra/logisticComponents/heading'
import AuditTrailDetails from './CustomForms/auditTrailDetails'
const FormikForm = ({
  data,
  cta,
  formValidation,
  formName,
  onFormChange,
  setformName,
  setFormIcon,
  setUpdatedData,
  onFormClose,
  subheadingComponent,
  allItemsInTemplate,
  dropDownItemList = 'assemblyItem',
  cancelBtnIcon,
  cancelBtnText,
  okBtnIcon,
  okBtnText,
  setokBtnIcon,
  setokBtnText,
  callCreateNewItem,
  setCTA,
  setCancelBtnText,
  confirmSlide,
  setconfirmSlide,
  setisFormikChange,
  isFormikChange,
  addInventoryModal,
}) => {
  const [intialValuesFromProp, setIntialValuesFromProp] = useState()
  const [mediaInputOne, setMediaInputOne] = useState()
  const [showStorage, setShowStorage] = useState(false)
  const [selecteddropDowns, setselecteddropDowns] = useState([])
  const { uisettings, allItems, storageLocation, common, settings, orders } =
    useSelector((state) => state)
  const { dropdownItemList, activeDropdownValue } = common
  const [items, setItems] = useState([])
  const [errorMsg, seterrorMsg] = useState('')
  // const [confirmSlide, setconfirmSlide] = useState('');

  const [storageCheck, setstoragecheck] = useState()
  const formikRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const stateRedux = useSelector((state) => state)
  useEffect(() => {
    const initialValues = data
      ?.filter((rem) => !nameArray?.includes(rem.name))
      ?.map((value) => {
        return {
          [value.name]: !!value.initialValue ? value.initialValue : undefined,
        }
      })

    setIntialValuesFromProp(
      initialValues?.reduce((obj, item) => Object.assign(obj, item), {}),
    )
  }, [JSON.stringify(data)])

  useEffect(() => {
    if (allItemsInTemplate) {
      setItems(allItemsInTemplate)
    }
  }, [allItemsInTemplate])
  useEffect(() => {
    if (
      uisettings?.formName === 'create-assembly' &&
      activeDropdownValue?.name === 'Initial Request Assembly'
    ) {
      setselecteddropDowns(
        dropdownItemList?.[dropDownItemList]?.filter(
          (item) => item.itemCategory?.id !== 2,
        ),
      )
      data[2].required = false
      data[3].required = false
    } else if (
      uisettings?.formName === 'create-assembly' &&
      activeDropdownValue?.name === 'Shipping Assembly'
    ) {
      setselecteddropDowns(
        dropdownItemList?.[dropDownItemList]?.filter(
          (item) => item.itemCategory?.id === 2 || item.itemCategory?.id === 3,
        ),
      )
      data[2].required = false
      data[3].required = false
    } else if (
      uisettings?.formName === 'create-assembly' &&
      activeDropdownValue?.name === 'Return Kit Assembly'
    ) {
      setselecteddropDowns(dropdownItemList?.[dropDownItemList])
      data[2].required = true
      data[3].required = true
    } else if (
      uisettings?.formName === 'create-assembly' ||
      uisettings?.formName === 'copy-assembly-LO'
    ) {
      setselecteddropDowns(dropdownItemList?.[dropDownItemList])
    } else if (
      uisettings?.formName === 'create-kit-template' ||
      uisettings?.formName === 'copy-kit-template'
    ) {
      setselecteddropDowns(
        allItems?.allItems?.filter(
          (item) => item?.itemCategory.id !== 2 || item?.itemCategory.id !== 3,
        ),
      )
    }
  }, [activeDropdownValue, dropDownItemList, allItems, uisettings.formName])

  useEffect(() => {
    ; (async () => {
      setTimeout(async () => {
        await formikRef.current?.validateForm()
      }, 800)
    })()
  }, [])

  useEffect(() => {
    if (items?.length > 0) {
      const uniqueValues = new Set(items.map((v) => parseInt(v?.item?.id)))

      if (uniqueValues.size < items?.length) {
        seterrorMsg(`Can't have duplicate item`)
      } else {
        seterrorMsg('')
      }
    }
  }, [items])

  const handleFormChange = (event) => {
    if (onFormChange) {
      if (event?.target?.name && event.target.name === 'isParentLocation') {
        data[2].disabled = event.target.checked
      }

      if (event === '') {
        data[2].disabled = true
        data[2].required = false
      }
      if (event?.target?.name && event.target.name === 'formatId') {
        const formatId = parseInt(event?.target?.value)
        const validTypes = ['Single Select', 'Multiple Select']
        const validFormatIds = formats
          .filter((ele) => validTypes.includes(ele.name))
          .map((ele) => ele.id)
        if (formatId && validFormatIds.includes(formatId)) {
          data[2].disabled = false
          data[2].required = true
        } else {
          data[2].disabled = true
          data[2].required = false
        }
      }
      onFormChange(event)
    }
  }
  return uisettings.formName === 'create-kit-template' ||
    uisettings.formName === 'copy-kit-template' ? (
    <KitBuilder cta={cta} />
  ) : uisettings.formName === 'edit-kit-template' ? (
    <EditKitBuilder
      setUpdatedData={setUpdatedData}
      setformName={setformName}
      setFormIcon={setFormIcon}
      setokBtnIcon={setokBtnIcon}
      setokBtnText={setokBtnText}
      cta={cta}
    />
  ) : uisettings.formName === 'assign-shipping-rule' ? (
    <AssignShippingRules
      setformName={setformName}
      formName={formName}
      setFormIcon={setFormIcon}
      setconfirmSlide={setconfirmSlide}
      confirmSlide={confirmSlide}
      setisFormikChange={setisFormikChange}
      isFormikChange={isFormikChange}
    />
  ) : uisettings.formName === 'addKitToLogistic' ? (
    <AddTOKit />
  ) : uisettings.formName === 'addKitToLogisticAssemlyOnly' ? (
    <AddTOKit assemblyOnly />
  ) : uisettings.formName === 'add-new-region' ||
    uisettings.formName === 'edit-region' ? (
    <AddNewRegion />
  ) : uisettings.formName === 'assign-shipping-region-level' ||
    uisettings.formName === 'assign-shipping-study-level' ||
    uisettings?.formName === 'assign-shipping-country-level' ||
    uisettings?.formName === 'assign-kit-study-level' ||
    uisettings?.formName === 'assign-kit-region-level' ||
    uisettings?.formName === 'assign-kit-country-level' ||
    uisettings?.formName === 'assign-initial-kit-study-level' ||
    uisettings?.formName === 'assign-initial-kit-region-level' ||
    uisettings?.formName === 'assign-initial-kit-country-level' ? (
    <AssignShippingtoLower />
  ) : uisettings.formName === 'add-new-country' ? (
    <AddNewCountry />
  ) : uisettings.formName === 'add-new-site' ? (
    <AddNewSite />
  ) : uisettings.formName === 'un-configured-site' ? (
    <UnconfiSite />
  ) : uisettings.formName === 'approve-acknowledge' ? (
    <Acknowledgment approve setformName={setformName} />
  ) : uisettings.formName === 'reject-acknowledge' ? (
    <Acknowledgment setformName={setformName} />
  ) : uisettings.formName === 'create-package' ? (
    <CreatePackge />
  ) : uisettings.formName === 'ask-option-shipping-upload' ? (
    <UploadShipping />
  ): uisettings.formName === 'ask-option-document-upload' ? (
    <DocumentUpload />
  ) : uisettings.formName === 'mark-qc' ? (
    <MArkQc />
  ) : uisettings.formName === 'create-email-template' ? (
    <CreateEmail />
  ) : uisettings.formName === 'audit-trail-detail' ? (
    <AuditTrailDetails />
  ) : (
    intialValuesFromProp && (
      <Formik
        initialValues={{
          ...intialValuesFromProp,
          target: storageCheck || undefined,
        }}
        innerRef={formikRef}
        enableReinitialize
        validate={(values) => {
          const errors = {}
          data?.forEach((fields) => {
            if (
              (values?.[fields.name] === '' ||
                values?.[fields.name] === null ||
                values?.[fields.name] === undefined) &&
              fields.required
            ) {
              errors[fields.name] = 'Required'
            }
            if (
              fields.name === 'abbreviation' &&
              values?.[fields.name]?.length > 6
            ) {
              errors.abbreviation =
                'Sponsor Abbreviation cannot be greater than 6 character'
            }
            if (
              (fields.name === 'change_reason' ||
                fields.name === 'changeReason') &&
              !values?.[fields.name]?.trim() &&
              fields.required
            ) {
              errors[fields.name] = 'Required'
            }
            if (
              (fields.name === 'change_reason' ||
                fields.name === 'changeReason') &&
              values?.[fields.name]?.trim()?.length > 200 &&
              fields.required
            ) {
              errors[fields.name] = 'max 200 character allowed'
            }
            if (
              fields.name === 'closeToExpiryNumberOfDays' &&
              values?.['CloseToExpiryAlert'] &&
              !values?.['closeToExpiryNumberOfDays']
            ) {
              errors[fields.name] = 'Required'
            }
            if (
              fields.name === 'userIds' &&
              fields.required &&
              fields.type === 'multiSelect' &&
              !!values?.['userIds'] &&
              values?.[fields.name]?.length === 0
            ) {
              errors[fields.name] = 'Required'
            }
          })
          if (formValidation) {
            formValidation(errors, values, uisettings)
          }
          return errors
        }}
        onSubmit={(values) => {
          dispatch(showErrorReducer(''))
          if (uisettings.formName === 'addInventory') {
            var addInevntoryPayload = {}

            Object.keys(values).forEach((data) => {
              if (isNaN(data)) {
                var nonCustomId =
                  storageLocation.itemCategories?.fields?.filter(
                    (field) => field.mergeFieldKey === data,
                  )[0]?.id
                if (data === 'StorageLocation') {
                  addInevntoryPayload[String(nonCustomId)] = String(
                    values[data]?.id,
                  )
                } else {
                  if (data !== 'locationId') {
                    addInevntoryPayload[String(nonCustomId)] = values[data]
                      ? String(values[data])
                      : undefined
                  }
                }
              } else {
                addInevntoryPayload[String(data)] = values[data]
                  ? String(values[data]?.id || values[data])
                  : undefined
              }
            })
            cta(values.ItemName, addInevntoryPayload, values, stateRedux)
          } else {
            if (!errorMsg || uisettings.formName !== 'create-assembly') {
              cta(values, items, stateRedux)
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          initialValues,

          /* and other goodies */
        }) => {
          return (
            <>
              {/* {formName === "Add Inventory" && (
            <div className="mb-[25px] pb-[10px] border-b ">
              <HemaValue text="Standard Fields" />
            </div>
          )} */}
              {uisettings.formName === 'confirm-kit-template' &&
                !showStorage && (
                  <div className="mb-[10px] border border-solid border-[#DEE2E6] rounded">
                    <DataTable
                      columns={[
                        {
                          name: 'Name',
                          selector: (row) => row?.name,
                        },
                        {
                          name: 'Quantity',
                          selector: (row) => row?.selectedQuantity,
                        },
                        {
                          name: 'Lot Number',
                          selector: (row) => row?.lotNumber || 'N/A',
                        },
                        {
                          name: 'Expiration Date',
                          selector: (row) => row?.expirationDate || 'N/A',
                        },
                      ]}
                      data={[uisettings.editForm]}
                    />
                  </div>
                )}

              {showStorage && (
                <div className="absolute top-[170px] z-30 h-screen w-full bg-white ">
                  <StorageLocationForm
                    controller={formikRef}
                    setShowStorage={setShowStorage}
                  />
                </div>
              )}
              <form
                onSubmit={handleSubmit}
                onChange={handleFormChange}
                className={` ${showStorage && 'hidden'
                  } max-w-[600px] overflow-auto m-auto pr-4 flex flex-col  h-[calc(100%-50px)] ${uisettings.formName === 'additem' && data?.[0]?.name === 'inventory_confirm' ? 'justify-start' : 'justify-between'}`}
              >
                <div className="flex flex-wrap w-full gap-[16px] ">
                  {data?.map((fields, counter) => {
                    return (
                      <div
                        key={counter}
                        className={` ${fields?.fluid ? 'w-full' : 'w-[calc(50%-8px)]'
                          }  ${(fields?.type === 'table' ||
                            fields?.type === 'tableDate') &&
                          'flex'
                          } ${fields?.className ===
                          'delete-email-templates-textarea' &&
                          'flex items-start justify-between '
                          } `}
                      >
                        {/* {fields.type === 'textarea' && uisettings?.formName === 'delete-inventory' && (
                      <div className="border-dashed border-t-2 border-[#DEE2E6] mb-[21px] mt-[10px]"></div>
                    )}
                    {fields.name === 'closeToExpiryNumberOfDays' && uisettings?.formName === 'expire-inventory' && (
                      <div className="border-dashed border-t-2 border-[#DEE2E6] mb-[21px] mt-[10px]"></div>
                    )} */}
                        {fields.label && (
                          <HemaLabel
                            isLower={
                              uisettings?.formName === 'delete-kit-config' ||
                              fields.noCapital ||
                              fields?.type === 'table' ||
                              fields.noCapital
                            }
                            type={fields.type}
                            text={fields.label}
                            Icon={fields.Icon}
                            //required={fields.required}
                            required={fields.required}
                            className={`${fields.type === 'table'
                              ? 'flex w-full'
                              : 'mb-[10px]'
                              } ${fields.type === 'tableDate' && 'flex-1'} ${fields.className
                              }`}
                          />
                        )}
                        {(fields.type === 'text' ||
                          fields.type === 'number' ||
                          fields.type === 'date' ||
                          fields.type === 'email' ||
                          fields.type === 'datetime-local' ||
                          fields.type === 'time' ||
                          fields.type === 'color') && (
                            <div
                              className={`flex gap-[15px] items-center ${fields.type === 'number' && 'number-input-style'
                                }`}
                            >
                              <FormText
                                type={fields.type}
                                name={fields.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                value={values?.[fields.name]}
                                placeholder={fields.placeholder}
                                min={fields.min}
                                step={fields.step}
                                disabled={fields.disabled}
                                showTotal={fields.showTotal}
                                onClick={fields.onClick}
                                crossIcon={
                                  values?.[fields.name] ? <CrossIcon /> : ''
                                }
                                className="w-full"
                              />
                              {fields.showTotal && (
                                <HemaValue
                                  className="whitespace-nowrap"
                                  text={`/ ${uisettings.editForm?.quantity ||
                                    uisettings.editForm?.availableToBuild ||
                                    0
                                    }`}
                                />
                              )}
                            </div>
                          )}

                        {fields.type === 'tableDate' && (
                          <div className="flex gap-[15px] items-center flex-1">
                            <FormText
                              type={'date'}
                              name={fields.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              value={values?.[fields.name]}
                              placeholder={fields.placeholder}
                              disabled={fields.disabled}
                              onClick={fields.onClick}
                              //crossIcon={values?.[fields.name] ? <CrossIcon /> : ''}
                              className="w-full"
                            />
                          </div>
                        )}

                        {fields.type === 'checkbox' && (
                          <FormCheckbox
                            type={fields.type}
                            name={fields.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.[fields.name]}
                            disabled={fields.disabled}
                            checked={values?.[fields.name]}
                          />
                        )}
                        {fields.type === 'singleSelect' && (
                          <SingleSelect
                            setFieldValue={setFieldValue}
                            name={fields.name}
                            value={values?.[fields.name]}
                            placeholder={fields.placeholder}
                            options={fields.options}
                            disabled={fields.disabled}
                            dispatch={dispatch}
                            crossIcon={
                              values?.[fields.name] ? <CrossIcon /> : ''
                            }
                            formikRef={formikRef}
                          />
                        )}
                        {fields.type === 'searchableSelect' && (
                          <FormSearchableSelect
                            setFieldValue={setFieldValue}
                            name={fields.name}
                            onChange={handleFormChange}
                            value={values?.[fields.name] || ''}
                            placeholder={fields.placeholder}
                            options={fields.options}
                            dispatch={dispatch}
                            crossIcon={
                              values?.[fields.name] ? <CrossIcon /> : ''
                            }
                            dropIcon={<SelectDownArrow />}
                            // notRefresh={uisettings?.formName === 'add-company-location' ? false : true}
                            notRefresh
                            customItem
                            clickNewItem={() => callCreateNewItem()}
                            formikRef={formikRef}
                          />
                        )}
                        {fields.type === 'select' && (
                          <FormSelect
                            setFieldValue={setFieldValue}
                            name={fields.name}
                            onChange={handleFormChange}
                            value={values?.[fields.name]}
                            placeholder={fields.placeholder}
                            options={fields.options}
                            disabled={
                              uisettings?.formName === 'create-assembly'
                                ? common?.activeDropdownValue &&
                                  (common?.activeDropdownValue?.name ===
                                    'Initial Request Assembly' ||
                                    common?.activeDropdownValue?.name ===
                                    'Shipping Assembly')
                                  ? fields?.name === 'testingLabId' ||
                                    fields?.name ===
                                    'inboundShippingConditionId'
                                    ? true
                                    : false
                                  : false
                                : fields.disabled
                            }
                            dispatch={dispatch}
                            crossIcon={
                              values?.[fields.name] ? <CrossIcon /> : ''
                            }
                            dropIcon={<SelectDownArrow />}
                            formikRef={formikRef}
                          />
                        )}
                        {fields.type === 'multiSelect' && (
                          <FormMultiSelect
                            options={fields.options}
                            placeholder={fields.placeholder}
                            setFieldValue={setFieldValue}
                            name={fields.name}
                            value={values?.[fields.name]}
                            disabled={fields.disabled}
                            formikRef={formikRef}
                          />
                        )}
                        {fields.type === 'multiSelecttags' && (
                          <FormTags
                            placeholder={fields.options}
                            setFieldValue={setFieldValue}
                            name={fields.name}
                            value={values?.[fields.name]}
                          />
                        )}
                        {fields.type === 'textarea' && (
                          <FormTextarea
                            name={fields.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.[fields.name]}
                            placeholder={fields.placeholder}
                            options={fields.options}
                            disabled={fields.disabled}
                            rows={fields.rows}
                            className={fields.className}
                          />
                        )}
                        {fields.type === 'download-bulk' && (
                          <>
                            <div className="border-dashed border-t-2 border-[#DEE2E6] mb-[20px] mt-[16px]"></div>
                            <div className="flex justify-between w-full">
                              {fields.buttonText && (
                                <Button
                                  cta={fields.onClick}
                                  type="button"
                                  Icon={<ImportIcon />}
                                  className="text-[#605DAF] bg-white text-center flex justify-center  w-full wh"
                                  text={fields.buttonText}
                                />
                              )}
                            </div>
                          </>
                        )}

                        {fields.type === 'upload-image' && (
                          <ImageUpload
                            onClear={() => {
                              setFieldValue(fields.name, '')
                              fields.storageUrl = ''
                            }}
                            name={fields.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.[fields.name]}
                            placeholder={fields.placeholder}
                            options={fields.options}
                            setFieldValue={setFieldValue}
                            setMediaInputOne={setMediaInputOne}
                            mediaInputOne={mediaInputOne}
                            accept={fields.accept}
                            storageUrl={fields.storageUrl}
                          />
                        )}
                        {fields.type === 'viewItems' && (
                          <ViewTable heading allItems={values?.[fields.name]} />
                        )}
                        {fields.type === 'viewKitItem' && (
                          <ViewKitItemTable
                            heading
                            allItems={values?.[fields.name]}
                          />
                        )}
                        {fields.type === 'no-data' && (
                          <Nodata allItems={values?.[fields.name]} />
                        )}
                        {fields.type === 'switch' && (
                          <FormSwitch
                            name={fields.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.[fields.name]}
                            placeholder={fields.placeholder}
                            options={fields.options}
                            setFieldValue={setFieldValue}
                            initialValue={fields.initialValue}
                            values={values}
                            formikRef={formikRef}
                          />
                        )}
                        {fields.type === 'heading-only' && (
                          <div
                            className={`${uisettings.formName === 'add-to-shiment' &&
                              fields?.initialValue === 'OR'
                              ? 'or-style'
                              : ''
                              }`}
                          >
                            <Heading
                              heading={fields?.initialValue}
                              buttonData={undefined}
                              border={undefined}
                              mb={undefined}
                            />
                          </div>
                        )}

                        {fields.type === 'breadcrumb' && (
                          <>
                            <div className="flex flex-wrap">
                              {fields?.initialValue?.map((data) => {
                                return (
                                  <div>
                                    {data.name} &nbsp; &nbsp; &gt; &nbsp; &nbsp;{' '}
                                  </div>
                                )
                              })}
                              <span className="text-[#605DAF] bold">
                                {fields?.placeholder}
                              </span>
                            </div>
                            <div className="border-dotted border-[1px] border-[#DEE2E6] w-full h-[1px] mt-[8px]" />
                          </>
                        )}

                        {fields.type === 'Storage-Locations' && (
                          <>
                            <div className="flex items-center justify-between">
                              <HemaValue
                                text={values?.[fields.name]?.name}
                                className="py-[10px]"
                              />
                              {values?.[fields.name]?.name && (
                                <span
                                  onClick={async () => {
                                    formikRef.current?.setFieldTouched(
                                      fields.name,
                                      true,
                                    )
                                    setFieldValue(fields.name, '')
                                  }}
                                  className={`cursor-pointer`}
                                >
                                  <CrossIcon />
                                </span>
                              )}
                            </div>

                            <Button
                              type="button"
                              cta={() => {
                                setShowStorage(true)
                              }}
                              text={fields.placeholder}
                              className="w-full text-center border-2 border-[#605DAF] flex justify-center text-[#605DAF] "
                            />
                          </>
                        )}

                        {fields.type === 'formTable' && (
                          <CheckboxTable
                            name={fields.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.[fields.name]}
                            // placeholder={fields.placeholder}
                            // options={fields.options}
                            setFieldValue={setFieldValue}
                            // setMediaInputOne={setMediaInputOne}
                            // mediaInputOne={mediaInputOne}
                            // accept={fields.accept}
                            allSites={values?.[fields.name]}
                          />
                        )}
                        {fields.type === 'paragraph' && (
                          <HemaLabel text={fields.initialValue} />
                        )}
                        {fields.type === 'ScreeningKitTable' && (
                          <ScreeningKitTable
                            name={fields.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.[fields.name]}
                            // placeholder={fields.placeholder}
                            // options={fields.options}
                            setFieldValue={setFieldValue}
                            // setMediaInputOne={setMediaInputOne}
                            // mediaInputOne={mediaInputOne}
                            // accept={fields.accept}
                            allItems={values?.[fields.name]}
                          />
                        )}
                        {fields.type === 'ScreeningShippingTable' && (
                          <ScreeningShippingTable
                            name={fields.name}
                            allItems={uisettings.editForm?.groupedItemsByQuantity?.filter(
                              (data) => !data?.isAssignedToPackage,
                            )}
                            setFieldValue={setFieldValue}
                          />
                        )}

                        {fields.type === 'kitpreparationTable' && (
                          <KitpreparationTable
                            name={fields.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.[fields.name]}
                            // placeholder={fields.placeholder}
                            // options={fields.options}
                            setFieldValue={setFieldValue}
                            allAssemblies={values?.[fields.name]}
                            setformName={setformName}
                            setFormIcon={setFormIcon}
                            setokBtnText={setokBtnText}
                            setUpdatedData={setUpdatedData}
                            setCTA={setCTA}
                            setconfirmSlide={setconfirmSlide}
                            confirmSlide={confirmSlide}
                            setCancelBtnText={setCancelBtnText}
                            setokBtnIcon={setokBtnIcon}
                          />
                        )}
                        {fields.type === 'table' && (
                          <HemaValue
                            className={`${fields.type === 'table' ? 'table' : 'mb-[10px]'
                              }`}
                            color="#595959"
                            text={fields.initialValue || ''}
                          />
                        )}

                        {fields.type === 'explorer' && (
                          <LocationExplorer
                            disabled={fields.disabled}
                            onClick={() => handleFormChange(fields)}
                            width={`w-${fields.width}`}
                            height={`h-${fields.height}`}
                            text={fields.text || fields.placeholder}
                            setFieldValue={() => {
                              setstoragecheck(fields.data)
                            }}
                            name={fields.name}
                            initialValue={fields.initialValue}
                          />
                        )}
                        {errors[fields.name] &&
                          touched[fields.name] &&
                          fields.type !== 'CopyAsstextarea' && (
                            <div className="error text-[red] text-[12px] pt-[2px]">
                              {' '}
                              {errors[fields.name]}
                            </div>
                          )}
                        {fields.name === 'isActive' &&
                          uisettings?.formName === 'create-site' && (
                            <div className="w-full">
                              <div className="border-dashed border-t-2 border-[#DEE2E6] mb-[21px] w-full mt-[10px]"></div>
                              <HemaLabel
                                text="Principal Investigator"
                                textSize="text-[14px]"
                                className="font-bold"
                              />
                            </div>
                          )}
                      </div>
                    )
                  })}
                  {uisettings?.uploadProgress && (
                    <>
                      <div className="w-full">
                        <ProgressBar
                          className="file-progress"
                          now={uisettings?.uploadProgress}
                          label={
                            <div className="absolute w-full ">
                              <div className=" w-full flex items-start gap-[14px]">
                                <div className="w-[36px] h-[36px] flex-shrink-0 border-[4px] flex items-center justify-center bg-[#F2F4F7] border-solid border-[#F9FAFB] rounded-full ">
                                  <Pdf />
                                </div>
                                <div className="">
                                  <HemaValue
                                    className="font-semibold"
                                    color="text-[#232323]"
                                    text={
                                      'Washington University || USA || 12/05/2022.pdf'
                                    }
                                  />
                                  <HemaValue
                                    className="font-normal"
                                    text={'1.2 MB – 100% uploaded'}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </>
                  )}
                  {uisettings.formName === 'create-assembly' ||
                    uisettings.formName === 'copy-assembly-LO' ||
                    formName === 'request-details' ? (
                    <div className="w-full">
                      <div className="border-b border-dashed border-[#DEE2E6] mb-[21px] " />
                      <div className="flex items-align py-[11px] border w-full rounded-t pl-[15px] pr-[35px]  justify-between ">
                        <div className="pl-[30px]">
                          <HemaValue
                            color="text-[#000000]"
                            className="text-xs  font-[400]"
                            text="Item Name"
                          />
                        </div>
                        <div>
                          <HemaValue
                            color="text-[#000000]"
                            className="text-xs  font-[400]"
                            text="Quantity Per Kit"
                          />
                        </div>
                      </div>
                      {items.map((item, index) => (
                        <DataTableRowItems
                          index={index}
                          item={item}
                          setItems={setItems}
                          allItems={selecteddropDowns}
                          items={items}
                          hideSort
                        />
                      ))}
                      <NewRow
                        dropDownItemList={dropDownItemList}
                        addRow={(data) => {
                          let id = data.obj?.id || data?.itemName
                          var itemName
                          let customItem = data.obj.cutomItem || false
                          if (data.obj) {
                            itemName = data.obj.name
                          } else {
                            itemName = allItems?.allItems?.filter(
                              (itm) => String(itm.id) === id,
                            )?.[0]?.name
                          }
                          setItems([
                            ...items,
                            {
                              id: String(Date.now()),
                              sort: items.length + 1,
                              itemId: id,
                              item: { id: id, name: itemName },
                              qty: 1,
                              name: customItem ? data.Search : itemName,
                              customItem: customItem,
                            },
                          ])
                        }}
                        confirmSlide
                        setItems={setItems}
                        setFieldValue={setFieldValue}
                        hideSort
                        data={data}
                        disabled={values?.assemblyTypeId ? false : true}
                        allItemsInTemplate={allItemsInTemplate}
                      />
                      {errorMsg && (
                        <div className="error text-[red] text-[12px] pt-[2px]">
                          {errorMsg}
                        </div>
                      )}
                      {uisettings.formName === 'copy-assembly-LO' &&
                        data?.map((fields) => {
                          if (
                            fields.name === 'change_reason' &&
                            fields.type === 'CopyAsstextarea'
                          ) {
                            return (
                              <div className="mt-3">
                                <HemaLabel
                                  type={fields.type}
                                  text={'Change Reason'}
                                  Icon={fields.Icon}
                                  required={fields.required}
                                  className={`mb-[10px] ${fields.className}`}
                                />
                                <FormTextarea
                                  name={fields.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.[fields.name]}
                                  placeholder={
                                    fields.placeholder || 'Type reason'
                                  }
                                  options={fields.options}
                                />
                                {errors[fields.name] &&
                                  touched[fields.name] && (
                                    <div className="error text-[red] text-[12px] pt-[2px]">
                                      {' '}
                                      {errors[fields.name]}
                                    </div>
                                  )}
                              </div>
                            )
                          } else {
                            return false
                          }
                        })}
                    </div>
                  ) : null}
                </div>

                <div className="flex gap-[8px] justify-end my-[20px]">
                  <Button
                    cta={() => {
                      if (onFormClose) {
                        onFormClose()
                      }
                      if (confirmSlide?.buildType) {
                        setconfirmSlide('')
                        setokBtnText('Save')
                        setCancelBtnText('Cancel')
                        setCTA(() => async () => {
                          dispatch(
                            setForm({
                              state: false,
                              type: '',
                            }),
                          )
                        })
                      } else {
                        dispatch(setForm({ state: false, type: '' }))
                        if (setCancelBtnText) {
                          setCancelBtnText('Cancel')
                        }
                      }
                    }}
                    type="button"
                    text={cancelBtnText || 'Cancel'}
                    bg="bg-white"
                    border="border-primary1"
                    color="text-primary1"
                    Icon={<Cancel />}
                  />
                  {uisettings?.formName === 'build-screening-kit' && (
                    <Button
                      type="button"
                      text={'Add Inventory'}
                      bg="bg-primary1"
                      color="text-white"
                      Icon={<Add color="#FFF" />}
                      cta={() => {
                        // navigate('/inventory-management');
                        dispatch(
                          setForm({
                            state: false,
                            type: '',
                          }),
                        )
                        addInventoryModal()
                      }}
                    />
                  )}
                  {uisettings?.formLoader ? (
                    <Button
                      type="button"
                      bg="bg-primary1"
                      text={
                        <>
                          <Spinner animation="grow" size="sm" variant="light" />
                          <Spinner animation="grow" size="sm" variant="light" />
                          <Spinner animation="grow" size="sm" variant="light" />
                        </>
                      }
                    />
                  ) : ClsoeBtnForm?.includes(uisettings?.formName) ? null : (
                    <Button
                      disabled={
                        compareArrays(
                          initialValues,
                          values,
                          uisettings?.formName,
                          items,
                        )
                          ? true
                          : Object.keys(errors)?.length
                            ? true
                            : false
                      }
                      type="submit"
                      text={okBtnText || 'Save'}
                      bg="bg-primary1"
                      color="text-white"
                      Icon={okBtnIcon || <Confirm color={undefined} />}
                    />
                  )}
                </div>
              </form>
            </>
          )
        }}
      </Formik>
    )
  )
}

export default FormikForm
