import { post, get, put, remove } from './http';
import { errorHandling } from './error';
import { setUploadProgress } from '../Store/reducers/uiSettings';
import { store } from '../Store';
const getDashbaordStats = async (id) => {
  try {
    return await get(`/order/dashboard`);
  } catch (err) {
    //errorHandling(err?.response?.data)
    return err?.response?.data;
  }
};

const getShippingStats = async (orderId) => {
  try {
    return await get(`/shipping/orders/${orderId}/shipments`);
  } catch (err) {
    //errorHandling(err?.response?.data)
    return err?.response?.data;
  }
};
const getkittemplateViewItem = async (itemId) => {
  try {
    return await get(`inventory/items/${itemId}/kit-template`);
  } catch (err) {
    //errorHandling(err?.response?.data)
    return err?.response?.data;
  }
};

const orderShipDocument = async (orderId, shipmentId) => {
  try {
    return await get(`/order/orders/${orderId}/shipments/${shipmentId}/documents`);
  } catch (err) {
    //errorHandling(err?.response?.data)
    return err?.response?.data;
  }
};

const getAllOrders = async (id) => {
  try {
    return await get(`/order/orders`);
  } catch (err) {
    //errorHandling(err?.response?.data)
    return err?.response?.data;
  }
};
const getdocumentDetail = async (orderId, orderItemId, type, fullyId) => {
  try {
    if (fullyId) {
      return await get(`order/orders/${orderId}/items/${orderItemId}/documents/${type}/${fullyId}`);
    } else {
      return await get(`order/orders/${orderId}/items/${orderItemId}/documents/${type}`);
    }
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const getshipmentDocumentDetails = async (orderId, shipmentId, type) => {
  try {
    return await get(`/order/orders/${orderId}/shipments/${shipmentId}/documents/${type}`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getpackingDocumentDetail = async (orderId, shipmentId, packageId, data) => {
  try {
    if (!!data) {
      return await post(`/order/orders/${orderId}/shipments/${shipmentId}/packages/${packageId}/documents/packing-slip`, data, {
        headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
      });
    } else {
      return await get(`/order/orders/${orderId}/shipments/${shipmentId}/packages/${packageId}/documents/packing-slip`);
    }
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getGreenLightdetail = async (orderId, shipmentId) => {
  try {
    return await get(`shipping/orders/${orderId}/shipments/${shipmentId}/green-lights`);
  } catch (err) {
    //errorHandling(err?.response?.data)
    return err?.response?.data;
  }
};

const getInvenotryItemforBuild = async (itemId, data) => {
  try {
    return await get(`inventory/items/${itemId}/inventories/ready?minQuantity=${data}`);
  } catch (err) {
    //errorHandling(err?.response?.data)
    return err?.response?.data;
  }
};
const getAssembbliesforItem = async (orderId, orderItemFulfillmentId) => {
  try {
    return await get(`order/orders/${orderId}/staging-items/${orderItemFulfillmentId}/assemblies`);
  } catch (err) {
    //errorHandling(err?.response?.data)
    return err?.response?.data;
  }
};

const getAllBuildPreparation = async (state, orderId) => {
  try {
    return await get(`order/orders/${orderId}/preparation/${state}`);
  } catch (err) {
    //errorHandling(err?.response?.data)
    return err?.response?.data;
  }
};

const approveAcknowledge = async (id, data) => {
  try {
    return await post(`/order/orders/${id}/request/acknowledgement/approve`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const rejectAcknowledge = async (id, data) => {
  try {
    return await post(`/order/orders/${id}/request/acknowledgement/reject`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const submitGreenLight = async (orderId, shipmentId, data) => {
  try {
    return await post(`shipping/orders/${orderId}/shipments/${shipmentId}/green-lights/submit`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const reSubmitGreenLightApproval = async (orderId, shipmentId, greenId, data) => {
  try {
    return await post(`shipping/orders/${orderId}/shipments/${shipmentId}/green-lights/${greenId}/resend`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const cancelGreenLightApproval = async (orderId, shipmentId, greenLightId, data) => {
  try {
    return await post(`shipping/orders/${orderId}/shipments/${shipmentId}/green-lights/${greenLightId}/cancel`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const attachFiletoGreenLight = async (orderId, shipmentId, greenLightId, data) => {
  try {
    return await put(`shipping/orders/${orderId}/shipments/${shipmentId}/green-lights/${greenLightId}/files`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const updateGreenLight = async (orderId, shipmentId, data) => {
  try {
    return await post(`shipping/orders/${orderId}/shipments/${shipmentId}/green-lights/submit/extra`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const generateAllTypeDocument = async (orderId, orderItemId, fulfillmentParentId, type, data, autoGenerate=false) => {
  try {
    console.log('generateAllTypeDocument')
    if (type === 'return-air-way-bill') {
      if (fulfillmentParentId) {
        if(autoGenerate)
          return await post(`/order/orders/${orderId}/items/${orderItemId}/documents/${type}/${fulfillmentParentId}/auto-generate`, data, {
            headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
          });
        else
          return await post(`/order/orders/${orderId}/items/${orderItemId}/documents/${type}/${fulfillmentParentId}`, data, {
            headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
          });
      } else {
        return await post(`/order/orders/${orderId}/items/${orderItemId}/documents/${type}/auto-generate`, data, {
          headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
        });
      }
    } else {
      if (fulfillmentParentId) {
        return await post(`/order/orders/${orderId}/items/${orderItemId}/documents/${type}/${fulfillmentParentId}`, data, {
          headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
        });
      } else {
        return await post(`/order/orders/${orderId}/items/${orderItemId}/documents/${type}`, data, {
          headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
        });
      }
    }
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const generateShippmentDcoument = async (orderId, shipmentId, type, data) => {
  try {
    if (type === 'air-way-bill' || type === 'depot-air-way-bill') {
      return await post(`/order/orders/${orderId}/shipments/${shipmentId}/documents/${type}/auto-generate`, data, {
        headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
      });
    } else {
      return await post(`/order/orders/${orderId}/shipments/${shipmentId}/documents/${type}`, data, {
        headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
      });
    }
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const generateManualUpload = async (orderId, shipmentId, type, data) => {
  try {
    return await post(`/order/orders/${orderId}/shipments/${shipmentId}/documents/${type}/manual`, data, {
      headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getAcknowledgedetail = async (id, rejectProp) => {
  try {
    return await get(`/order/orders/${id}/request/acknowledgement${rejectProp ? '/reject' : ''}`);
  } catch (err) {
    // errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const createOrder = async (data) => {
  try {
    return await post(`/order/orders/request`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getDimenisonsForPackage = async () => {
  try {
    return await get(`/shipping/packages/dimensions`);
  } catch (err) {
    return err?.response?.data;
  }
};

const updateOrder = async (id, data) => {
  try {
    return await put(`/order/orders/${id}/request`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const unAssignLineItem = async (orderId, orderItemId, assignType) => {
  try {
    return await post(`order/orders/${orderId}/items/${orderItemId}/${assignType}`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const AssignPreparationKit = async (orderId, data, fullfillmentId) => {
  try {
    if (fullfillmentId) {
      return await post(`order/orders/${orderId}/assign?orderItemParentFulfillmentId=${fullfillmentId}`, data);
    } else {
      return await post(`order/orders/${orderId}/assign`, data);
    }
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const AddFiletoGreenLight = async (orderId, shipmentId, greenLightId, desc, data) => {
  try {
    return await post(`shipping/orders/${orderId}/shipments/${shipmentId}/green-lights/${greenLightId}/files?description=${desc}`, data, {
      headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const uploadfileSeedGreenlight = async (orderId, shipmentId, greenLightId, submissionFileId, data) => {
  try {
    return await post(`/shipping/orders/${orderId}/shipments/${shipmentId}/green-lights/${greenLightId}/files/${submissionFileId}`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const attachPreSeededFile = async (orderId, shipmentId, greenLightId, submissionFileId, data) => {
  try {
    return await put(`/shipping/orders/${orderId}/shipments/${shipmentId}/green-lights/${greenLightId}/files/${submissionFileId}`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const AssignPreparationLineItem = async (orderId, orderItemId, fullyId) => {
  try {
    if (fullyId) {
      return await post(`order/orders/${orderId}/items/${orderItemId}/assign-custom-line-item?orderItemParentFulfillmentId=${fullyId}`);
    } else {
      return await post(`order/orders/${orderId}/items/${orderItemId}/assign-custom-line-item`);
    }
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getdefaultOrderValues = async (siteId, typeId) => {
  try {
    return await get(`/order/orders/request?siteId=${siteId}&TypeId=${typeId}`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const orderAvailableItems = async (siteId, typeId) => {
  try {
    return await get(`/order/available-items?siteId=${siteId}&TypeId=${typeId}`);
  } catch (err) {
    return err?.response?.data;
  }
};

const getOrderDetail = async (id) => {
  try {
    return await get(`/order/orders/${id}/request`);
  } catch (err) {
    return err?.response?.data;
  }
};

const getAllAddress = async (id) => {
  try {
    return await get(`/order/addresses`);
  } catch (err) {
    return err?.response?.data;
  }
};
const createAddress = async (data) => {
  try {
    return await post(`/order/addresses`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getAllShipmentForOrderId = async (id) => {
  try {
    return await get(`/shipping/orders/${id}/shipments`);
  } catch (err) {
    return err?.response?.data;
  }
};

const createNewShipment = async (id, data) => {
  try {
    return await post(`/shipping/orders/${id}/shipments`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const updateShipment = async (id, shipmentId, data) => {
  try {
    return await put(`/shipping/orders/${id}/shipments/${shipmentId}`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getShipmentDetails = async (id, shipmentId) => {
  try {
    return await get(`/shipping/orders/${id}/shipments/${shipmentId}`);
  } catch (err) {
    return err?.response?.data;
  }
};

const deleteShipment = async (id, shipmentId) => {
  try {
    return await remove(`/shipping/orders/${id}/shipments/${shipmentId}`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const unAssignedItemsFromShuioment = async (id, shipmentId, data) => {
  try {
    return await post(`/shipping/orders/${id}/shipments/${shipmentId}/un-assign`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const createPackage = async (id, shipmentId, data) => {
  try {
    return await post(`/shipping/orders/${id}/shipments/${shipmentId}/package`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const unlockPackge = async (id, shipmentId, packageId) => {
  try {
    return await post(`/shipping/orders/${id}/shipments/${shipmentId}/package/${packageId}/unlock`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const updatePackage = async (id, shipmentId, packageId, data) => {
  try {
    return await put(`/shipping/orders/${id}/shipments/${shipmentId}/package/${packageId}`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const deletePackage = async (id, shipmentId, packageId) => {
  try {
    return await remove(`/shipping/orders/${id}/shipments/${shipmentId}/package/${packageId}`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};
const deletegreenLightDocument = async (orderId, shipmentId, greenLightId, fileId) => {
  try {
    return await remove(`shipping/orders/${orderId}/shipments/${shipmentId}/green-lights/${greenLightId}/files/${fileId}`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getQualityControlItem = async (id, shipmentId) => {
  try {
    return await get(`/shipping/orders/${id}/shipments/${shipmentId}/quality-control`);
  } catch (err) {
    return err?.response?.data;
  }
};

const updateQualityStatus = async (id, shipmentId, data) => {
  try {
    return await post(`/shipping/orders/${id}/shipments/${shipmentId}/quality-control`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const shiptheShipment = async (id, shipmentId) => {
  try {
    return await post(`/shipping/orders/${id}/shipments/${shipmentId}/ship`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getPostShipmentTemplate = async (id, shipmentId, typeId) => {
  try {
    return await get(`/shipping/orders/${id}/shipments/${shipmentId}/post-shipping/${typeId}`);
  } catch (err) {
    return err?.response?.data;
  }
};

const updatePostShipmentTemplate = async (id, shipmentId, data, typeId) => {
  try {
    return await post(`/shipping/orders/${id}/shipments/${shipmentId}/post-shipping/${typeId}`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const sendPostShipping = async (id, shipmentId, typeId) => {
  try {
    return await post(`/shipping/orders/${id}/shipments/${shipmentId}/post-shipping/${typeId}/send`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const unlockShipping = async (id, shipmentId) => {
  try {
    return await post(`/shipping/orders/${id}/shipments/${shipmentId}/unlock`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const courierServiceType = async (id) => {
  try {
    return await get(`/order/couriers/${id}/services`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getdynamicdocs = async (id, shipmentId) => {
  try {
    return await get(`/order/orders/${id}/shipments/${shipmentId}/shipping-documents`);
  } catch (err) {
    return err?.response?.data;
  }
};

//orders

const getSettingsList = async (id) => {
  try {
    return await get(`/order/settings`);
  } catch (err) {
    return err?.response?.data;
  }
};

const updateSettingList = async (settingId, data) => {
  try {
    return await put(`/order/settings/${settingId}`, data);
  } catch (err) {
    return err?.response?.data;
  }
};

const getAllDocumentsForOrder = async (id) => {
  try {
    return await get(`/order/orders/${id}/documents`);
  } catch (err) {
    return err?.response?.data;
  }
};

const getAllType = async () => {
  try {
    return await get(`/order/order-document/all-types`);
  } catch (err) {
    return err?.response?.data;
  }
};

const createOrderType = async (data) => {
  try {
    return await post(`/order/order-document/types`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const createNewDoument = async (id, docId, data) => {
  try {
    return await post(`/order/orders/${id}/documents/${docId}/upload`, data, null, {
      onUploadProgress: (progressEvent) => {
        store.dispatch(setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
      },
    });
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

export {
  getSettingsList,
  updateSettingList,
  generateManualUpload,
  getDimenisonsForPackage,
  getdynamicdocs,
  courierServiceType,
  createNewDoument,
  getAllDocumentsForOrder,
  getAllType,
  createOrderType,
  createAddress,
  unlockShipping,
  unlockPackge,
  unAssignedItemsFromShuioment,
  deleteShipment,
  getPostShipmentTemplate,
  updatePostShipmentTemplate,
  sendPostShipping,
  shiptheShipment,
  updateQualityStatus,
  getQualityControlItem,
  deletePackage,
  createPackage,
  updatePackage,
  updateShipment,
  createNewShipment,
  getAllShipmentForOrderId,
  updateOrder,
  getAcknowledgedetail,
  getAllAddress,
  orderAvailableItems,
  getdefaultOrderValues,
  createOrder,
  getDashbaordStats,
  getAllOrders,
  rejectAcknowledge,
  approveAcknowledge,
  getOrderDetail,
  getAllBuildPreparation,
  unAssignLineItem,
  AssignPreparationKit,
  getShippingStats,
  AssignPreparationLineItem,
  getInvenotryItemforBuild,
  getAssembbliesforItem,
  getShipmentDetails,
  getGreenLightdetail,
  AddFiletoGreenLight,
  deletegreenLightDocument,
  submitGreenLight,
  orderShipDocument,
  attachFiletoGreenLight,
  uploadfileSeedGreenlight,
  attachPreSeededFile,
  getdocumentDetail,
  generateAllTypeDocument,
  getpackingDocumentDetail,
  getshipmentDocumentDetails,
  generateShippmentDcoument,
  updateGreenLight,
  cancelGreenLightApproval,
  reSubmitGreenLightApproval,
  getkittemplateViewItem,
};
