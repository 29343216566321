import React, { useEffect, useState, useRef } from 'react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import DataTable from 'react-data-table-component'
import Spinner from 'react-bootstrap/Spinner'
import {
  HemaLabel,
  FormText,
  Button,
  HemaValue,
  FormTextarea,
  FormSelect,
} from '../../utils'
import Skeleton from 'react-loading-skeleton'
import {
  Cancel,
  CrossIcon,
  Edit,
  Print,
  UploadIcon,
  SelectDownArrow,
} from '../../HemeIconLibrary'
import Heading from '../../pages/logistOrchestra/logisticComponents/heading'
import { showErrorReducer } from '../../Store/reducers/uiSettings'
import { useSelector } from 'react-redux'
import KitBuilder from './details'
const DocumentForm = (props) => {
  const {
    data,
    setBuildTemplate,
    cta,
    okBtnIcon,
    okBtnText,
    docRef,
    handlePrint,
    openEditor,
    setopenEditor,
    loadFile,
    pdfRef,
    formValidation,
  } = props
  const [intialValuesFromProp, setIntialValuesFromProp] = useState()
  const { uisettings, orders, common } = useSelector((state) => state)
  const [items, setItems] = useState([])
  const formikRef = useRef()
  const uploadRef = useRef()
  const dispatch = useDispatch()
  const stateRedux = useSelector((state) => state)
  // function onPrintDocument() {
  //   //Print the document content.
  //   if (openEditor) {
  //     docRef.current?.documentEditor?.print();
  //   }
  // }

  useEffect(() => {
    if (common?.activeDropdownValue?.name === 'Courier') {
      formikRef.current?.setFieldValue('CourierServiceTypeId', '')
    }
  }, [common?.activeDropdownValue])

  useEffect(() => {
    const initialValues = data?.map((value) => {
      return {
        [value.name]: !!value.initialValue ? value.initialValue : undefined,
      }
    })

    setIntialValuesFromProp(
      initialValues?.reduce((obj, item) => Object.assign(obj, item), {}),
    )
  }, [JSON.stringify(data)])

  return (
    <Formik
      initialValues={{
        ...intialValuesFromProp,
      }}
      innerRef={formikRef}
      enableReinitialize
      validate={(values) => {
        const errors = {}
        data?.forEach((fields) => {
          if (
            (values?.[fields.name] === '' ||
              values?.[fields.name] === null ||
              values?.[fields.name] === undefined) &&
            fields.required &&
            openEditor
          ) {
            errors[fields.name] = 'Required'
          }
          if (
            fields.type === 'tabeDetails' &&
            !values?.[fields.name]?.filter((f) => f.unitPrice)?.length &&
            uisettings?.buildTemplate?.orderItemDetail?.buildType ===
              'pro-forma' &&
            openEditor
          ) {
            errors[fields.name] = 'Required'
          }
        })
        if (formValidation) {
          formValidation(errors, values, uisettings)
        }
        return errors
      }}
      onSubmit={(values) => {
        dispatch(showErrorReducer(''))

        //todo: re-enable this line for editing file
       // if (openEditor) {
       
          window.scrollTo(0, 0)
          cta(values, items, stateRedux, true)

         //todo: re-enable this line for editing file
        // } else {
        //   setopenEditor(true)
        // }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        dirty,
        isValid,
        validateForm,
        /* and other goodies */
      }) => (
        <>
          <form
            onSubmit={handleSubmit}
            className="max-w-[600px] overflow-auto m-auto pr-4 flex flex-col  h-[calc(100%-50px)] justify-between"
          >
            {/* <Heading heading={heading1} mb /> */}
            <div className="flex flex-wrap w-full gap-[16px] ">
              {data?.length > 0 ? (
                data?.map((fields, counter) => {
                  return (
                    !fields.hidden && (
                      <div
                        key={counter}
                        className={` ${
                          fields?.fluid ? 'w-full' : 'w-[calc(50%-8px)]'
                        }  ${
                          (fields?.type === 'table' ||
                            fields?.type === 'tableDate') &&
                          'flex'
                        }`}
                      >
                        {/* {fields.type === 'textarea' && uisettings?.formName === 'delete-inventory' && (
                      <div className="border-dashed border-t-2 border-[#DEE2E6] mb-[21px] mt-[10px]"></div>
                    )}
                    {fields.name === 'closeToExpiryNumberOfDays' && uisettings?.formName === 'expire-inventory' && (
                      <div className="border-dashed border-t-2 border-[#DEE2E6] mb-[21px] mt-[10px]"></div>
                    )} */}
                        <HemaLabel
                          type={fields.type}
                          text={fields.label}
                          Icon={fields.Icon}
                          required={fields.required}
                          className={`${
                            fields.type === 'table' ? 'mb-[1px]' : 'mb-[10px]'
                          } ${fields.type === 'tableDate' && 'flex-1'} ${
                            fields.className
                          }`}
                        />
                        {(fields.type === 'text' ||
                          fields.type === 'number' ||
                          fields.type === 'date' ||
                          fields.type === 'email' ||
                          fields.type === 'datetime-local' ||
                          fields.type === 'time' ||
                          fields.type === 'color') && (
                          <>
                            <div className="flex gap-[15px] items-center">
                              <FormText
                                type={fields.type}
                                name={fields.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                value={values?.[fields.name]}
                                placeholder={fields.placeholder}
                                min={fields.min}
                                step={fields.step}
                                disabled={fields.disabled}
                                showTotal={fields.showTotal}
                                onClick={fields.onClick}
                                crossIcon={
                                  values?.[fields.name] ? <CrossIcon /> : ''
                                }
                                className="w-full"
                              />
                            </div>

                            {errors[fields.name] && touched[fields.name] && (
                              <div className="error text-[red] text-[12px] pt-[2px]">
                                {' '}
                                {errors[fields.name]}
                              </div>
                            )}
                          </>
                        )}
                        {fields.type === 'textarea' && (
                          <FormTextarea
                            name={fields.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.[fields.name]}
                            placeholder={fields.placeholder}
                            options={fields.options}
                            disabled={fields.disabled}
                          />
                        )}
                        {fields.type === 'heading-only' && (
                          <div className="">
                            <Heading heading={fields?.initialValue} />
                          </div>
                        )}
                        {fields.type === 'select' && (
                          <>
                            {fields.name === 'CourierServiceTypeId' ? (
                              <FormSelect
                                setFieldValue={setFieldValue}
                                name={fields.name}
                                value={values?.[fields.name]}
                                placeholder={fields.placeholder}
                                options={orders?.couriertype}
                                disabled={fields.disabled}
                                dispatch={dispatch}
                                crossIcon={
                                  values?.[fields.name] ? <CrossIcon /> : ''
                                }
                                dropIcon={<SelectDownArrow />}
                              />
                            ) : (
                              <FormSelect
                                setFieldValue={setFieldValue}
                                name={fields.name}
                                value={values?.[fields.name]}
                                placeholder={fields.placeholder}
                                options={fields.options}
                                disabled={fields.disabled}
                                dispatch={dispatch}
                                crossIcon={
                                  values?.[fields.name] ? <CrossIcon /> : ''
                                }
                                dropIcon={<SelectDownArrow />}
                              />
                            )}
                            {errors[fields.name] && touched[fields.name] && (
                              <div className="error text-[red] text-[12px] pt-[2px]">
                                {' '}
                                {errors[fields.name]}
                              </div>
                            )}
                          </>
                        )}
                        {fields.type === 'tabeDetails' && (
                          <>
                            <KitBuilder
                              data={values?.[fields.name]}
                              name={fields.name}
                              setFieldValue={(name, data) => {
                                setFieldValue(name, data)
                              }}
                              priceError
                              showItemPrice={
                                uisettings?.buildTemplate?.orderItemDetail
                                  ?.buildType === 'pro-forma'
                                  ? true
                                  : false
                              }
                            />
                            {errors[fields.name] && touched[fields.name] && (
                              <div className="error text-[red] text-[12px] pt-[2px]">
                                {' '}
                                {errors[fields.name]}
                              </div>
                            )}
                          </>
                        )}

                        {fields.type === 'packaging-content' && (
                          <div className="w-full border border-solid border-[#DEE2E6] rounded">
                            <div className="p-[10px] border-b-[1px] border-solid border-b-[#DEE2E6]">
                              <h2 className="m-0 text-base font-bold text-primary1">
                                Package Contents
                              </h2>
                            </div>
                            <DataTable
                              data={values?.[fields.name] || []}
                              columns={[
                                {
                                  name: (
                                    <HemaValue
                                      text={'Item'}
                                      className="font-normal text-[#000000]"
                                    />
                                  ),
                                  sortable: true,
                                  selector: (row) => (
                                    <HemaValue text={row?.kitAliasName || 0} />
                                  ),
                                  sortId: 'name',
                                },
                                {
                                  right: true,
                                  name: (
                                    <HemaValue
                                      text={'Amount'}
                                      className="font-normal text-[#000000]"
                                    />
                                  ),
                                  sortable: true,
                                  selector: (row) => (
                                    <HemaValue text={row?.quantity || 0} />
                                  ),
                                  sortId: 'Amount',
                                },
                              ]}
                              customStyles={{
                                responsiveWrapper: {
                                  style: { overflow: 'visible !important' },
                                },
                              }}
                              // onSort={(row, direction, sorted) => {
                              //   setfilteredData(sortedData(row.sortId, direction, sorted));
                              // }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  )
                })
              ) : (
                <div className="w-full">
                  <br />
                  <Skeleton count={4} />
                  <br />
                  <Skeleton count={4} />
                  <br />
                  <Skeleton count={4} />
                  <br />
                  <Skeleton count={4} />
                  <br />
                </div>
              )}
            </div>

            <div className="flex gap-[8px] justify-end mt-[40px]">
              <Button
                cta={() => {
                  dispatch(setBuildTemplate({}))
                }}
                type="button"
                text={'Close'}
                bg="bg-white"
                p
                border="border-primary1"
                color="text-primary1"
                Icon={<Cancel />}
              />
              {uisettings?.buildTemplate?.orderItemDetail?.buildType !==
                'awb' &&
                uisettings?.buildTemplate?.orderItemDetail?.buildType !==
                  'depot-awb' && (
                  <Button
                    p
                    type="button"
                    text={okBtnText || 'Upload'}
                    bg="bg-primary1"
                    //disabled={openEditor ? false : true}
                    color="text-white"
                    cta={() => {

                      uploadRef?.current?.click()
                    }}
                    Icon={okBtnIcon || <UploadIcon />}
                  />
                )}
              <input
                type="file"
                onChange={(e) => {
                  const accept = [
                    'application/pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ]
                  const fileObj = e.target.files?.[0]
                  var fsize = fileObj.size
                  fsize = Math.round(fsize / 1024)
                  if (!accept?.includes(fileObj.type)) {
                    window.scrollTo(0, 0)
                    dispatch(
                      showErrorReducer(
                        'Invalid file type (Recomended word document)',
                      ),
                    )
                    return true
                  }
                  if (fsize >= 15000) {
                    window.scrollTo(0, 0)
                    dispatch(
                      showErrorReducer('Max file size exceeded(Max 15MB).'),
                    )
                    return
                  }
                  //todo: re-enable this line for editing file
                  //loadFile(e.target.files)
                  validateForm().then((data) => {
                    if (Object.keys(data)?.length === 0) {
                      cta(values, items, stateRedux, false, fileObj)
                    } else {
                      dispatch(
                        showErrorReducer(
                          'kindly fill all required fields first.',
                        ),
                      )
                      window.scrollTo(0, 0)
                    }
                  })
                }}
                onClick={(event) => {
                  event.target.value = null
                }}
                className="hidden"
                ref={uploadRef}
              />
              <Button
                p
                type="button"
                text={okBtnText || 'Print'}
                bg="bg-primary1"
                color="text-white"
                disabled={openEditor ? true : false}
                // onPrintDocument();
                cta={() => {
                  window.scroll(0, 0)
                  pdfRef?.current?.print.print()
                }}
                Icon={okBtnIcon || <Print />}
              />
              {uisettings?.formLoader ? (
                <Button
                  type="submit"
                  bg="bg-primary1"
                  text={
                    <>
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                    </>
                  }
                />
              ) : (
                
                <Button
                  p
                  type="submit"
                  text={'Generate'}
                  bg="bg-primary1"
                  color="text-white"
                  // disabled={isPrintAble ? true : false}
                  Icon={okBtnIcon || <Edit color="white" />}
                />
                
              )}
            </div>
          </form>
        </>
      )}
    </Formik>
  )
}

export default DocumentForm
